import React from "react";
import "./App.css";
import kickcloud from './kickcloud.png';
import kickcloud1 from './kickcloud-1.png';
import kickcloud1a from './kickcloud-1a.png';

function App() {
  return (
    <div className="App">
      <Header />

      <div className="container">
        <FeatureSection
          title="Student Management"
          description="Effortlessly manage student profiles, track progress, and maintain attendance records."
        />
        <FeatureSection
          title="Class Scheduling"
          description="Schedule classes, assign instructors, and manage bookings with ease."
        />
        <FeatureSection
          title="Billing & Payments"
          description="Handle memberships, automate monthly billing, and process online payments seamlessly."
        />

        <ContactSection />
      </div>

      <Footer />
    </div>
  );
}

const Header = () => (
  <header>
    <h1>Welcome to KickCloud.io</h1>
    <img src={kickcloud1a} alt="logo" width="200"/>
    <p>Your go-to solution for martial arts studio management.</p>
    <a href="mailto:info@kickcloud.io?subject=Learn%20more&body=I'm%20interested%20in%20learning%20more%20about%20kickcloud!" className="btn">
      Email info@kickcloud.io to learn more
    </a>
  </header>
);

const FeatureSection = ({ title, description }) => (
  <section className="feature">
    <h2>{title}</h2>
    <p>{description}</p>
  </section>
);

const ContactSection = () => (
  <section>
    <h2>Contact</h2>
    <p>Have questions? Reach out to us at support@kickcloud.io</p>
  </section>
);

const Footer = () => (
  <footer>
    <p>&copy; 2023 KickCloud.io. All rights reserved.</p>
  </footer>
);

export default App;
